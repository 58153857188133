import Papa from "papaparse";

type Student = [string, string];

type Data = { status: "not-started" } | { status: "loading" } | { status: "succeeded"; students: Student[] } | { status: "failed"; error: string };

let csvUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQsdJ0xzfA-RR6Ra-VjnI_6zcWKONYI2uhs7NTkFPEP1WKVLsDRFxjpjHlb6F84pu3dSJt29ukbDV0z/pub?gid=1873910888&single=true&output=csv";

let state = {
  search: "",
  currentPage: 0,
  studentsPerPage: 25,
  data: { status: "not-started" } as Data,
};

let loadStudentList = async () => {
  const response = await fetch(csvUrl);
  const csvContent = await response.text();
  const jsonContent = Papa.parse(csvContent);

  state.data = {
    status: "succeeded",
    students: jsonContent.data.reverse(),
  };
};

let getStudentList = (search?: string) => {
  if (state.data.status !== "succeeded") {
    return { numberOfPage: 0, pageStudents: [] };
  }

  const students = state.data.students.filter(([name]) => {
    if (!search) {
      return true;
    }

    return (
      name
        .toLowerCase()
        .replace(/^\s+|\s+$/g, "")
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  const { studentsPerPage, currentPage } = state;

  return {
    numberOfPage: Math.ceil(students.length / studentsPerPage),
    pageStudents: students.slice(currentPage * studentsPerPage, currentPage * studentsPerPage + studentsPerPage),
  };
};

let refreshView = () => {
  const { pageStudents, numberOfPage } = getStudentList(state.search);

  $("#students").find("tr:gt(0)").remove();

  for (let [name, licenseDate] of pageStudents) {
    $("#students tr:last").after(`
      <tr>
        <td><i class=""></i></td>
        <td>${name}</td>
        <td>${licenseDate}</td>
      </tr>
    `);
  }

  $(".current-page").text(state.currentPage + 1);
  $(".previous-page").toggleClass("hide", state.currentPage === 0);
  $(".next-page").toggleClass("hide", state.currentPage + 1 >= numberOfPage);
};

$("#our-student-link").on("click", () => {
  loadStudentList().then(() => refreshView());
});

$(".previous-page").on("click", () => {
  state.currentPage--;
  refreshView();
});

$(".next-page").on("click", () => {
  state.currentPage++;
  refreshView();
});

$("#student-search").on("input", () => {
  state.search = $("#student-search").val();
  state.currentPage = 0;

  refreshView();
});
