import "jq-bootstrap-validation/dist/jq.bootstrap.validation.min";

$(function () {
  $("#contactForm input,#contactForm textarea").jqBootstrapValidation({
    preventSubmit: true,
    submitSuccess: function ($form, event) {
      event.preventDefault();

      var name = $("input#name").val();
      var email = $("input#email").val();
      var phone = $("input#phone").val();
      var message = $("textarea#message").val();
      var firstName = name;

      if (firstName.indexOf(" ") >= 0) {
        firstName = name.split(" ").slice(0, -1).join(" ");
      }

      $.ajax({
        url: "https://formsubmit.co/ajax/3c6e5cf2c0fb1bc1812afe44496dfad7",
        method: "POST",
        data: {
          _replyto: email,
          _subject: "Nouveau message depuis top-conduite.com",
          message: `${name} (tel: ${phone}, email: ${email}) : \n\n ${message}`,
        },
        success: () => {
          // Success message
          $("#success").html("<div class='alert alert-success'>");
          $("#success > .alert-success").html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;").append("</button>");
          $("#success > .alert-success").append("<strong>Votre message à été envoyé !</strong>");
          $("#success > .alert-success").append("</div>");

          //clear all fields
          $("#contactForm").trigger("reset");
        },
        error: () => {
          $("#success").html("<div class='alert alert-danger'>");
          $("#success > .alert-danger").html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;").append("</button>");
          $("#success > .alert-danger").append("<strong>Désolé " + firstName + ", Il semble que notre serveur ne répond pas. Merci de réesayer plus tard!");
          $("#success > .alert-danger").append("</div>");
        },
      });
    },
    filter: function () {
      return $(this).is(":visible");
    },
  });

  $('a[data-toggle="tab"]').click(function (e) {
    e.preventDefault();
    $(this).tab("show");
  });
});

$("#name").focus(function () {
  $("#success").html("");
});
