import "regenerator-runtime/runtime";
import "./import-jquery";
import "jquery.easing/jquery.easing";
import "bootstrap/dist/js/bootstrap";
import "./contact-form";
import "./students-list";

import classie from "classie/index";

$(function () {
  $("a.page-scroll").bind("click", function (event) {
    let $anchor = $(this);

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $($anchor.attr("href")).offset().top,
        },
        1500,
        "easeInOutExpo"
      );
    event.preventDefault();
  });
});

// Highlight the top nav as scrolling occurs
$("body").scrollspy({
  target: ".navbar-fixed-top",
});

// Closes the Responsive Menu on Menu Item Click
$(".navbar-collapse ul li a")
  .not(".dropdown-toggle")
  .click(function () {
    $(".navbar-toggle:visible").click();
  });

(function () {
  let docElem = document.documentElement,
    header = document.querySelector(".navbar"),
    didScroll = false,
    changeHeaderOn = 200;

  function init() {
    window.addEventListener(
      "scroll",
      function (event) {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 250);
        }
      },
      false
    );
  }

  function scrollPage() {
    let sy = scrollY();

    if (sy >= changeHeaderOn) {
      classie.add(header, "navbar-shrink");
    } else {
      classie.remove(header, "navbar-shrink");
    }

    didScroll = false;
  }

  function scrollY() {
    return window.pageYOffset || docElem.scrollTop;
  }

  init();
})();
